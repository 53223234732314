import { defineStore } from "pinia";
import { ref } from "vue";

export const useTestStore = defineStore("testStore", () => {
	//state
	const requestedTests = ref<any[]>([]);
	const passedTests = ref<any[]>([]);
	const failedTests = ref<any[]>([]);
	const currentTest = ref<string>("");
	const availableTests = ref<any>([]);
	const testsCategory = ref<any>([]);
	const checkinTestPassed = ref<boolean>(false);

	//actions
	const setRequestedTests = (tests: any[]) => {
		requestedTests.value = tests;
	};
	const setPassedTests = (tests: any[]) => {
		passedTests.value = tests;
	};
	const setFailedTests = (tests: any[]) => {
		failedTests.value = tests;
	};
	const setCurrentTest = (testName: string) => {
		currentTest.value = testName;
	};
	const setAvailableTests = (tests: any) => {
		availableTests.value = tests;
	};

	const setTestsCategory = (category: any) => {
		testsCategory.value = category;
	};

	const setCheckinTestPassed = (state: any) => {
		checkinTestPassed.value = state;
	};

	function resetValues() {
		passedTests.value = [];
		failedTests.value = [];
		requestedTests.value = [];
		currentTest.value = "";
	}

	return {
		requestedTests,
		availableTests,
		passedTests,
		failedTests,
		currentTest,
		setRequestedTests,
		setPassedTests,
		setFailedTests,
		setCurrentTest,
		setAvailableTests,
		setTestsCategory,
		testsCategory,
		resetValues,
		setCheckinTestPassed,
		checkinTestPassed,
	};
});

export default useTestStore;
