<script setup lang="ts">
// dependencies
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
	SafetyChecklistItem,
	SafetyChecklistObject,
} from "@/utils/types";

//imports
import { useOrderStore, useLoaderStore } from "@/store";
import Spinner from "@/components/common/Spinner.vue";
import $toast from "@/utils/toast";
import CancelReason from "@/components/common/CancelReason.vue";
import {
	fetchTaskByTaskID,
	fetchHealthAndSafetyChecks,
} from "@/services/order.service";
import {
	updateTaskState,
	addCustomerItemSafety,
} from "@/services/task.service";
import { Task_State_Enum } from "@/sdk";
import { TYPE } from "vue-toastification";
//actions
// import { checkOrderPriority } from "@/utils/order";
const orderStore = useOrderStore();
const loaderStore = useLoaderStore();

const router = useRouter();
const route = useRoute();

const checkedHS = ref<boolean[]>([]);
const hseChecklist = ref<any[]>([]);
const loading = ref<boolean>(false);

const interval = ref<ReturnType<typeof setInterval>>();

const cancelling = ref<boolean>(false);
function openCancelOrder() {
	cancelling.value = true;
}
function closeCancelReasonModal() {
	cancelling.value = false;
}

async function handleSubmitReport() {
	clearInterval(interval.value);
	loaderStore.toggleLoader({ type: "common", state: true });
	const checklistIDs: string[] = [];
	hseChecklist.value?.forEach((element: SafetyChecklistItem) => {
		checklistIDs.push(element.id);
	});
	const customerOrderSafetyChecklistObject: SafetyChecklistObject[] =
		[];
	checklistIDs.forEach((checklistID: string) => {
		customerOrderSafetyChecklistObject.push({
			customer_order_item_id: `${orderStore.getCurrentOrder?.customer_order?.customer_order_items[0]?.id}`,
			is_active: true,
			key: "DRIVER",
			value: "true",
			safety_checklist_id: checklistID,
		});
	});

	try {
		await addCustomerItemSafety(customerOrderSafetyChecklistObject);
		if (orderStore.getCurrentOrder?.state === "IN_TRANSIT") {
			await updateTaskState(
				route.params.taskID as string,
				Task_State_Enum.Arrived,
			);
		}
		router.push("/choose-assets/" + route.params.taskID);
	} catch (err) {
		$toast("Error submitting report", TYPE.ERROR);
		loaderStore.toggleLoader({ type: "common", state: false });
		throw new Error("Error:", { cause: err });
	} finally {
		loaderStore.toggleLoader({ type: "common", state: false });
	}
}

onMounted(async () => {
	try {
		loading.value = true;
		await fetchTaskByTaskID(route.params?.taskID as string);
		hseChecklist.value = (
			await fetchHealthAndSafetyChecks()
		).safety_checklist;
	} catch (error) {
		console.error("Error during onMounted:", error);
	}finally {
		loading.value = false;
	}
});


onUnmounted(() => {
	clearInterval(interval.value);
});
</script>

<template>
	<div class="relative">
		<div
			class="min-h-[100vh] flex flex-col items-center justify-between relative px-2 py-0 overflow-hidden bg-gray-700 text-white !rounded-none">
			<div
				class="w-11/12 flex flex-col justify-between items-center mt-4">
				<div
					class="justify-center flex flex-col space-y-2 w-[96%] mx-auto pt-0">
					<!-- heading and buttons -->
					<div class="flex justify-between items-center sticky">
						<h1 class="text-left font-normal text-2xl">
							Health and Safety
						</h1>
					</div>
					<div
						v-if="!hseChecklist.length"
						class="h-[calc(100vh-150px)] container-center justify-center">
						<Spinner
							:color="'white-spin'"
							:size="'spinner-lg'"
							class="container-center" />
						<!-- checks list and buttons -->
					</div>
					<div
						class="flex flex-col items-center overflow-auto max-h-[calc(100vh-9rem)] py-2">
						<div class="flex flex-col space-y-4">
							<div
								v-for="item in hseChecklist"
								:key="item.id"
								class="flex space-x-4 w-full">
								<div
									class="flex flex-col items-center justify-center">
									<input
										type="checkbox"
										class="h-6 w-6 !bg-white"
										:value="item.id"
										v-model="checkedHS" />
								</div>
								<label>{{ item.name }}</label>
							</div>
						</div>
						<div class="flex w-full px-4 absolute bottom-4">
							<div href="" class="w-full">
								<button
									@click.prevent="openCancelOrder"
									class="btn btn-red !btn-md text-lg h-full w-11/12">
									Cancel Request
								</button>
							</div>

							<button
								@click.prevent="handleSubmitReport()"
								class="btn btn-green !btn-md text-lg w-[49%] h-full"
								:disabled="
									!(checkedHS.length === hseChecklist.length) || loading
								">
								Submit Report
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<CancelReason
			:cancelling="cancelling"
			:close-cancel-reason-modal="closeCancelReasonModal" />
	</div>
</template>

<style scoped></style>
