import dayjs from 'dayjs';
import client from "./client";
import { useUserStore } from "@/store/userStore";

export const getDriverShiftSchedule = async () => {
	const userStore = useUserStore();
	const today = dayjs().format();
	try {
		const response = await (
			await client
		)?.getShiftSchedule({
			dateTime: today,
		});
		if (response?.shift_schedule.length === 0) {
			return null;
		}

		userStore.setShiftSchedule(response?.shift_schedule[0]);

		return response?.shift_schedule[0];
	} catch (err) {
		console.error(err);
		throw new Error("Error fetching driver shift schedule");
	}
};