export const requests = [
	{ id: 1, desc: "Accident." },
	{ id: 2, desc: "Mechanical Issue." },
	{ id: 3, desc: "Sickness while on duty." },
	{ id: 4, desc: "QR code/Identification number Issues." },
	{ id: 5, desc: "Addition/Removal of vehicles." },
	{ id: 6, desc: "Health and Safety Issue." },
];

export const cancelReasons = [
	"Customer Facility related",
	"Customer requested to reschedule",
	"No response from the Customer",
	"Order Placed at Wrong Location",
	"Customer/POC not available at the Site",
	"Cheque Not Ready",
	"Vehicle Met with Accident",
	"RTO Officer held Vehicle",
	"Vehicle Breakdown",
	"Driver/Operator Not feeling Well",
	"Other",
	"Issue with Asset Identification",
];
