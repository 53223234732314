<template>
	<!-- CANCEL REASON modal -->
	<Teleport to="#modal">
		<input
			type="checkbox"
			id="my-modal"
			class="modal-toggle"
			:checked="cancelling" />
		<div class="modal">
			<div
				class="relative w-11/12 flex flex-col items-center justify-center bg-white rounded-lg px-4 py-4">
				<button
					@click.prevent="closeCancelReasonModal"
					for="my-modal-3"
					class="modal-box btn btn-red btn-sm !btn-circle absolute -right-3 -top-3 z-[150]">
					<X class="mx-auto h-[18px]" />
				</button>

				<div
					class="flex flex-col items-center justify-center space-y-4 w-full">
					<form action="" class="flex flex-col items-center">
						<div class="w-fit !px-2">
							<label for="reason" class="ml-0 text-md font-bold"
								>Reason for Cancelling Order</label
							>
							<select
								name="reason"
								class="py-1 border text-sm bg-white rounded-md shadow"
								v-model="cancelreason"
								autofocus
								required>
								<option value="" disabled selected>
									Choose a reason
								</option>
								<option
									v-if="
										router.currentRoute.value.path.includes(
											'Dispenser',
										)
									"
									value="Dispenser not connected">
									Dispenser not connected
								</option>
								<option
									v-if="
										orderStore?.getCurrentOrder?.customer_order
											?.customer_order_customer_assets[0]
											.customer_asset?.tag_id
									"
									value="Issue with Asset Identification"
									default>
									RFID Scanner not working
								</option>
								<option
									v-for="(reason, index) in cancelReasons"
									:key="index"
									:value="reason">
									{{ reason }}
								</option>
							</select>
						</div>

						<textarea
							v-if="cancelreason === 'Other'"
							rows="1"
							cols="10"
							class="input h-40 bg-gray-50 text-black text-sm w-[95%] mt-4 p-2"
							placeholder="Mention reason for cancelling order."
							v-model="cancelreasonOther"></textarea>
						<textarea
							v-if="cancelreason === 'Other'"
							rows="1"
							cols="10"
							class="input h-40 bg-gray-50 text-black text-sm w-[95%] mt-4 p-2"
							placeholder="Mention reason for cancelling order."
							v-model="cancelreasonOther"></textarea>

						<button
							@click.prevent="cancelOrder"
							class="btn bg-red-500 mt-4 !normal-case btn-sm mx-auto">
							Cancel Request
						</button>
					</form>
				</div>
			</div>
		</div>
	</Teleport>
</template>

<script setup lang="ts">
//dependencies
import { ref } from "vue";
import { useRouter } from "vue-router";

//store
import { useOrderStore } from "@/store";
//service
import {
	updateTaskState,
	addTaskCancelReason,
} from "@/services/task.service";
import X from "@/assets/x.svg?component";

import { cancelReasons } from "./requestsList";
import { Task_State_Enum } from "@/sdk";
import $toast from "@/utils/toast";
import { TYPE } from "vue-toastification";

const router = useRouter();

const orderStore = useOrderStore();

type Props = {
	cancelling: boolean;
	closeCancelReasonModal: () => void;
};
const props = defineProps<Props>();

//cancellation logic
const cancelreason = ref<string>("");
const cancelreasonOther = ref<string>("");

async function cancelOrder() {
	try {
		if (cancelreason.value === "Other") {
			if (cancelreasonOther.value.length >= 20) {
				await updateTaskState(
					orderStore.getCurrentOrder?.id,
					Task_State_Enum.CancellationRequested,
				);
				await addTaskCancelReason({
					task_id: orderStore.getCurrentOrder?.id,
					reason: cancelreasonOther.value,
				});
				orderStore.resetStore();
				router.replace("/dashboard");
			} else {
				alert("Give Reason for Cancellation in more than 20 letters");
			}
		} else if (cancelreason.value !== "Other") {
			if (cancelreason.value) {
				await updateTaskState(
					orderStore.getCurrentOrder?.id,
					Task_State_Enum.CancellationRequested,
				);
				await addTaskCancelReason({
					task_id: orderStore.getCurrentOrder?.id,
					reason: cancelreason.value,
				});
				orderStore.resetStore();
				router.replace("/dashboard");
			} else {
				alert("Give Reason for Cancellation");
			}
		}
	} catch (error) {
		console.error("Error during cancelOrder:", error);
		$toast("Error during cancelOrder. Please try again!", TYPE.ERROR);
		props.closeCancelReasonModal;
	}
}
</script>

<style scoped></style>
