import { defineStore } from "pinia";
import { ref } from "vue";
import { connectDispenser } from "@fuelbuddy/dispenser/dist/tsc/main";
import { IDispenser } from "@fuelbuddy/dispenser/dist/tsc/lib/IDispenser";
import { DispenserFactory } from "@fuelbuddy/dispenser/dist/tsc/lib/DispenserFactory";
import { useOrderStore } from "@/store";
import $toast from "@/utils/notification";

export const useDispenserStore = defineStore(
	"useDispenserStore",
	() => {
		//state
		const socket = ref<WebSocket | null>();
		const dispenser = ref<IDispenser | null>();
		const dispenseQuantity = ref<any>();
		const intervalInstance = ref<any>();
		const dispenserNotWorking = ref<boolean>(false);
		const dispenserReconnected = ref<number>(0);
		const switchModeModal = ref<boolean>(false);
		const setPresetModal = ref<boolean>(false);

		const currentPreset = ref<number>(0);
		const currentSelectedAssetForPreset = ref<any>();

		const clearSaleVisible = ref<boolean>(false);

		const isExternalPumpDispenser = ref<boolean>(false);
		const dispenserName = ref<string>("");
		const checksRequired = ref<boolean>(false);

		const dispenserModeOnline = ref<boolean>(false);

		const assetsWithPresetSet = ref<any[]>([]);
		const addAssetToPresetSetList = (asset: any) => {
			assetsWithPresetSet.value.push(asset);
		};
		const updateAssetsWithPresetSetList = (list: any[]) => {
			assetsWithPresetSet.value = list;
		};

		const setDispenserModeOnline = (state: boolean) => {
			dispenserModeOnline.value = state;
		};

		const toggleSwitchModeModal = (state: boolean) => {
			switchModeModal.value = state;
		};

		const setDispenserHasExternalPump = (state: boolean) => {
			isExternalPumpDispenser.value = state;
		};

		const setDispenserName = (name: string) => {
			dispenserName.value = name;
		};

		const setChecksRequired = (state: boolean) => {
			checksRequired.value = state;
		};

		const showClearSale = (state: boolean) => {
			clearSaleVisible.value = state;
		};

		const connectAgain = ref<boolean>(false);

		const toggleConnectAgain = (state: boolean) => {
			connectAgain.value = state;
		};

		const updateDispenserReconnectedCount = () => {
			dispenserReconnected.value++;
		};

		const setDispenserNotWorking = (state: boolean) => {
			dispenserNotWorking.value = state;
		};

		const setIntervalInstance = (instance: any) => {
			intervalInstance.value = instance;
		};

		// const timeoutPromise = new Promise((resolve, reject) => {
		// 	// Set a timeout for 10 seconds
		// 	setTimeout(async () => {
		// 		reject(
		// 			new Error("Command Timeout: The request took too long."),
		// 		);
		// 		alert(
		// 			"Dispenser is taking too long to response . Please reconnect it.",
		// 		);
		// 		await disconnectSocket();
		// 		updateDispenserReconnectedCount();
		// 		setDispenserNotWorking(true);
		// 	}, 30000);
		// });

		const disconnectSocket = async () => {
			socket.value?.close();
			dispenser.value = null;
		};

		const initSocket = async () => {
			setDispenserNotWorking(false);

			const orderStore = useOrderStore();

			if (dispenserReconnected.value > 1) {
				$toast("Reconnecting Dispenser ...", "error", "top");
			}

			try {
				socket.value = await connectDispenser(
					import.meta.env.VITE_DISPENSER_IP,
				);

				(socket.value as WebSocket).onclose = async () => {
					toggleConnectAgain(true);
					window.clearInterval(intervalInstance.value);
					if (
						!orderStore?.currentDriverOrder?.is_done_locally ||
						!(
							localStorage.getItem(
								"proceed_after_dispense/" +
									orderStore.currentDriverOrder.id,
							) === "true"
						)
					) {
						$toast(
							"Closing connection to dispenser. Please reconnect if required",
							"error",
							"top",
						);
					}

					setDispenserNotWorking(true);
					updateDispenserReconnectedCount();
				};

				try {
					const response = await DispenserFactory.initialize(
						socket.value as WebSocket,
					);

					dispenser.value = response;
					setDispenserNotWorking(false);

					if (dispenserReconnected.value > 1) {
						$toast("Dispenser Reconnected", "success", "top");
					}
					updateDispenserReconnectedCount();
				} catch (err) {
					setDispenserNotWorking(true);
					updateDispenserReconnectedCount();
					dispenser.value = null;
					throw new Error("Error initialize dispenser", {
						cause: err,
					});
				}
				return socket.value;
			} catch (err) {
				toggleConnectAgain(true);
				updateDispenserReconnectedCount();
				setDispenserNotWorking(true);
				dispenser.value = null;
				throw new Error("Error connecting to dispenser", {
					cause: err,
				});
			}
		};

		// for actions without values
		const callDispenserAction = async (
			callback1: any,
			callback2: any,
		) => {
			const orderStore = useOrderStore();

			if (dispenser.value) {
				try {
					// Use Promise.race to wait for either the API response or the timeout
					// const result = await Promise.race([
					// 	dispenser.value?.execute(callback1, callback2),
					// 	timeoutPromise,
					// ]);
					const result = await dispenser.value?.execute(
						callback1,
						callback2,
					);
					return result;
				} catch (err) {
					// window.clearInterval(intervalInstance.value);
					throw new Error("Error calling dispenser action", {
						cause: err,
					});
				}
			} else {
				if (!orderStore?.currentDriverOrder?.is_done_locally) {
					$toast("Please reconnect dispenser", "error", "top");
				}
			}
		};

		const callDispenserActionWithPriority = async (
			callback1: any,
			callback2: any,
		) => {
			const orderStore = useOrderStore();

			if (dispenser.value) {
				try {
					// Use Promise.race to wait for either the API response or the timeout
					// const result = await Promise.race([
					// 	dispenser.value?.execute(callback1, callback2),
					// 	timeoutPromise,
					// ]);
					const result = await dispenser.value?.executeInPriority(
						callback1,
						callback2,
					);
					return result;
				} catch (err) {
					// window.clearInterval(intervalInstance.value);
					throw new Error("Error calling dispenser action", {
						cause: err,
					});
				}
			} else {
				if (!orderStore?.currentDriverOrder?.is_done_locally) {
					$toast("Please reconnect dispenser", "error", "top");
				}
			}
		};

		const callDispenserActionWithValue = async (
			callback1: any,
			callback2: any,
			value?: any,
		) => {
			const orderStore = useOrderStore();

			if (dispenser.value) {
				try {
					// const result = await Promise.race([
					// 	await dispenser.value?.execute(
					// 		callback1,
					// 		callback2,
					// 		value,
					// 	),
					// 	timeoutPromise,
					// ]);
					const result = await dispenser.value?.execute(
						callback1,
						callback2,
						value,
					);
					return result;
				} catch (err) {
					// window.clearInterval(intervalInstance.value);
					throw new Error("Error calling dispenser action", {
						cause: err,
					});
				}
			} else {
				if (!orderStore?.currentDriverOrder?.is_done_locally) {
					$toast("Please reconnect dispenser", "error", "top");
				}
			}
		};

		const callDispenserActionWithValuePriority = async (
			callback1: any,
			callback2: any,
			value?: any,
		) => {
			const orderStore = useOrderStore();

			if (dispenser.value) {
				try {
					// const result = await Promise.race([
					// 	await dispenser.value?.execute(
					// 		callback1,
					// 		callback2,
					// 		value,
					// 	),
					// 	timeoutPromise,
					// ]);
					const result = await dispenser.value?.executeInPriority(
						callback1,
						callback2,
						value,
					);
					return result;
				} catch (err) {
					// window.clearInterval(intervalInstance.value);
					throw new Error("Error calling dispenser action", {
						cause: err,
					});
				}
			} else {
				if (!orderStore?.currentDriverOrder?.is_done_locally) {
					$toast("Please reconnect dispenser", "error", "top");
				}
			}
		};

		//Dispenser
		const readDispenserStatus = async () => {
			const response = await callDispenserAction(
				dispenser.value?.readStatus,
				dispenser.value?.processStatus,
			);
			return response;
		};

		const readTotalizerReading = async () => {
			const response = await callDispenserActionWithPriority(
				dispenser.value?.totalizer,
				dispenser.value?.processTotalizer,
			);
			return response;
		};

		const readTotalizerReadingWithBatch = async () => {
			const response = await callDispenserActionWithPriority(
				dispenser.value?.totalizer,
				dispenser.value?.processTotalizerWithBatch,
			);
			return response;
		};

		const printDispenseDetails = async (details: any) => {
			const response = await callDispenserActionWithValuePriority(
				dispenser.value?.printReceipt,
				dispenser.value?.processCommand,
				details,
			);
			return response;
		};

		const startPump = async () => {
			const response = await callDispenserActionWithPriority(
				dispenser.value?.startPump,
				dispenser.value?.processCommand,
			);
			return response;
		};

		const stopPump = async () => {
			const response = await callDispenserActionWithPriority(
				dispenser.value?.stopPump,
				dispenser.value?.processCommand,
			);
			return response;
		};

		const setPreset = async (value: number | string) => {
			const response = await callDispenserActionWithValuePriority(
				dispenser.value?.setPreset,
				dispenser.value?.processCommand,
				value,
			);
			dispenseQuantity.value = response;
			return response;
		};

		const readPreset = async () => {
			const response = await callDispenserAction(
				dispenser.value?.readPreset,
				dispenser.value?.processReadPreset,
			);
			return response;
		};

		const cancelPreset = async () => {
			const response = await callDispenserActionWithPriority(
				dispenser.value?.cancelPreset,
				dispenser.value?.processCommand,
			);
			return response;
		};

		const authorizeSale = async () => {
			const response = await callDispenserActionWithPriority(
				dispenser.value?.authorizeSale,
				dispenser.value?.processCommand,
			);
			return response;
		};

		const readSale = async () => {
			const response = await callDispenserAction(
				dispenser.value?.readSale,
				dispenser.value?.processReadSale,
			);
			return response;
		};

		const suspendSale = async () => {
			const response = await callDispenserActionWithPriority(
				dispenser.value?.suspendSale,
				dispenser.value?.processCommand,
			);
			return response;
		};

		const resumeSale = async () => {
			const response = await callDispenserActionWithPriority(
				dispenser.value?.resumeSale,
				dispenser.value?.processCommand,
			);
			return response;
		};

		const clearSale = async () => {
			const response = await callDispenserActionWithPriority(
				dispenser.value?.clearSale,
				dispenser.value?.processCommand,
			);

			return response;
		};

		const isNozzleOffHook = async () => {
			const response = await callDispenserAction(
				dispenser.value?.readStatus,
				dispenser.value?.isNozzleOffHook,
			);
			return response;
		};

		const isNozzleOnHook = async () => {
			const response = await callDispenserAction(
				dispenser.value?.readStatus,
				dispenser.value?.isNozzleOnHook,
			);
			return response;
		};

		const isDispenserOnline = async () => {
			const response = await callDispenserAction(
				dispenser.value?.readAuthorization,
				dispenser.value?.isOnline,
			);
			return response;
		};

		const isSaleClosable = async () => {
			const response = await callDispenserActionWithPriority(
				dispenser.value?.readStatus,
				dispenser.value?.isSaleCloseable,
			);
			return response;
		};

		const hasExternalPump = async () => {
			const response = await callDispenserAction(
				dispenser.value?.hasExternalPump,
				dispenser.value?.processExternalPump,
			);
			return response;
		};

		const startExternalPump = async () => {
			const response = await callDispenserActionWithPriority(
				dispenser.value?.startExternalPump,
				dispenser.value?.processExternalPump,
			);
			return response;
		};

		const stopExternalPump = async () => {
			const response = await callDispenserActionWithPriority(
				dispenser.value?.stopExternalPump,
				dispenser.value?.processExternalPump,
			);
			return response;
		};

		const isExternalPumpStopped = async () => {
			const response = await callDispenserActionWithPriority(
				dispenser.value?.readExternalPumpStatus,
				dispenser.value?.processExternalPump,
			);
			return response;
		};

		const hasChecksBeforePumpStart = async () => {
			const response = (
				dispenser.value as any
			).hasChecksBeforePumpStart();
			return response;
		};

		const switchToLocal = async () => {
			const response = await callDispenserAction(
				dispenser.value?.switchToLocal,
				dispenser.value?.processCommand,
			);
			return response;
		};

		const switchToRemote = async () => {
			const response = await callDispenserAction(
				dispenser.value?.switchToRemote,
				dispenser.value?.processCommand,
			);
			return response;
		};

		const checkDispencerType = async () => {
			const response = await dispenser.value?.execute(
				dispenser.value?.checkType,
			);
			return response;
		};

		const getDispenserLogs = async () => {
			if (dispenser.value && dispenser.value?.downloadLogs) {
				const response = dispenser.value?.downloadLogs();
				return response;
			}
		};

		const resetDispenserQueue = async () => {
			if (dispenser.value && dispenser.value?.resetQueue) {
				const response = dispenser.value?.resetQueue();
				return response;
			}
		};

		//E Lock
		const readELockStatus = async () => {
			const response = await callDispenserAction(
				dispenser.value?.elockStatus,
				dispenser.value?.processElockStatus,
			);
			return response;
		};

		const unlockElock = async () => {
			const response = await callDispenserAction(
				dispenser.value?.elockUnlock,
				dispenser.value?.processElockStatus,
			);
			return response;
		};

		const lockElock = async () => {
			const response = await callDispenserAction(
				dispenser.value?.elockLock,
				dispenser.value?.processElockStatus,
			);
			return response;
		};

		const resetELock = async () => {
			const response = await callDispenserAction(
				dispenser.value?.elockReset,
				dispenser.value?.processElockStatus,
			);
			return response;
		};

		//updated dispenser functions
		const isPumpStopped = async () => {
			const response = await callDispenserAction(
				dispenser.value?.readStatus,
				dispenser.value?.isPumpStopped,
			);
			return response;
		};

		const isReadyForPreset = async () => {
			const response = await callDispenserAction(
				dispenser.value?.readStatus,
				dispenser.value?.isReadyForPreset,
			);
			return response;
		};

		const isPresetVerified = async (num: number | string) => {
			const response = await callDispenserActionWithValuePriority(
				dispenser.value?.readPreset,
				dispenser.value?.isPresetVerified,
				num,
			);
			return response;
		};

		const isDispensing = async () => {
			const response = await callDispenserAction(
				dispenser.value?.readStatus,
				dispenser.value?.isDispensing,
			);
			return response;
		};

		const isOrderComplete = async (num: number | string) => {
			const response = await callDispenserActionWithValue(
				dispenser.value?.readSale,
				dispenser.value?.isOrderComplete,
				num,
			);
			return response;
		};

		const readStatus = async () => {
			const response = await callDispenserAction(
				dispenser.value?.readStatus,
				dispenser.value?.processStatus,
			);
			return response;
		};

		const isDispenserIdle = async () => {
			const response = await callDispenserAction(
				dispenser.value?.readStatus,
				dispenser.value?.isIdle,
			);
			return response;
		};

		//modals
		const openSetPresetModal = (state: boolean) => {
			setPresetModal.value = state;
		};

		//set variables
		const setCurrentPreset = (preset: number) => {
			currentPreset.value = preset;
		};

		const setCurrentSelectedAssetForPreset = (asset: any) => {
			currentSelectedAssetForPreset.value = asset;
		};

		const flowName = ref("");
		const setFlowName = ( name: string) => {
			flowName.value = name;
    };

		return {
			dispenser,
			socket,
			connectAgain,
			dispenseQuantity,
			intervalInstance,
			setIntervalInstance,
			initSocket,
			disconnectSocket,
			showClearSale,
			clearSaleVisible,
			//Dispenser actions
			dispenserNotWorking,
			setDispenserNotWorking,
			dispenserReconnected,
			toggleConnectAgain,
			readDispenserStatus,
			readTotalizerReading,
			readTotalizerReadingWithBatch,
			printDispenseDetails,
			startPump,
			stopPump,
			setPreset,
			readPreset,
			suspendSale,
			resumeSale,
			cancelPreset,
			authorizeSale,
			readSale,
			clearSale,
			isPumpStopped,
			isReadyForPreset,
			isPresetVerified,
			isOrderComplete,
			isDispensing,
			isNozzleOnHook,
			isNozzleOffHook,
			isDispenserOnline,
			isSaleClosable,
			hasExternalPump,
			startExternalPump,
			stopExternalPump,
			isExternalPumpStopped,
			isExternalPumpDispenser,
			setDispenserHasExternalPump,
			dispenserName,
			setDispenserName,
			hasChecksBeforePumpStart,
			setChecksRequired,
			checksRequired,
			setDispenserModeOnline,
			dispenserModeOnline,
			switchToLocal,
			switchToRemote,
			checkDispencerType,
			toggleSwitchModeModal,
			switchModeModal,
			updateDispenserReconnectedCount,
			getDispenserLogs,
			callDispenserActionWithPriority,
			callDispenserActionWithValuePriority,
			resetDispenserQueue,
			readStatus,
			isDispenserIdle,
			//E Lock actions
			readELockStatus,
			unlockElock,
			lockElock,
			resetELock,
			//modals
			openSetPresetModal,
			setPresetModal,
			//variables
			setCurrentPreset,
			currentPreset,
			setCurrentSelectedAssetForPreset,
			currentSelectedAssetForPreset,
			assetsWithPresetSet,
			addAssetToPresetSetList,
			updateAssetsWithPresetSetList,
			flowName,
			setFlowName,
		};
	},
);

export default useDispenserStore;
