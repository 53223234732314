<script setup lang="ts">
//dependencies
import { ref, onMounted, watch, computed } from "vue";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";

//store
import { useOrderStore, useUserStore } from "@/store";

//imports
import Spinner from "@/components/common/Spinner.vue";
import AssetCard from "@/components/steps/AssetCard.vue";
import OrderCard from "@/components/steps/OrderCard.vue";
import Search from "@/assets/search.svg?component";
import CancelReason from "@/components/common/CancelReason.vue";
// import ScanQrCodeModal from "@/components/steps/ScanQrCodeModal.vue";

//services
import {
	fetchCustomerAssetsByOrderID,
	fetchDriverVehicleByDriverVehicleId,
	fetchTaskByTaskID,
} from "@/services/order.service";
import { delay } from "@/utils/general";
import { CustomerAssetWithQuantityDispensed } from "@/utils/types";
import { getDriverShiftSchedule } from "@/services/user.service";

const route = useRoute();

const orderStore = useOrderStore();
const userStore = useUserStore();

const showProceed = ref<boolean>(false);
const router = useRouter();
const loadingAssets = ref<boolean>(false);

const proceed = async () => {
	try {
		router.push("/challan");
	} catch (e) {
		throw new Error("error proceeding to challan", { cause: e });
	}
};

const cancelling = ref<boolean>(false);
function openCancelOrder() {
	cancelling.value = true;
}
function closeCancelReasonModal() {
	cancelling.value = false;
}

//search
const searchQuery = ref<string>("");

const allAssetsNotDispensed = computed(() => {
	if (orderStore.getCurrentOrderAssets) {
		return orderStore.getCurrentOrderAssets.every(
			(asset: CustomerAssetWithQuantityDispensed) =>
				asset?.quantity_dispensed === null,
		);
	} else {
		return true;
	}
});

watch(searchQuery, async (updatedSearchQuery) => {
	await fetchCustomerAssetsByOrderID(
		orderStore.getCurrentOrder?.customer_order?.id,
		updatedSearchQuery,
	);
	updatedSearchQuery;
});

watch(orderStore.getCompletedAssetIds, (newCompletedAssetIDs) => {
	if (newCompletedAssetIDs.length > 0) {
		showProceed.value = true;
	} else {
		showProceed.value = false;
	}
});

onMounted(async () => {
	try {
		loadingAssets.value = true;
		if (orderStore.getCompletedAssetIds.length > 0) {
			showProceed.value = true;
		}
		/**
		 * Everytime this component gets mounted, recheck the order details
		 */
		await getDriverShiftSchedule();
		await fetchTaskByTaskID(
			typeof route.params.taskID === "string"
				? route.params.taskID
				: route.params.taskID[0],
		);
		await fetchCustomerAssetsByOrderID(
			orderStore.getCurrentOrder.customer_order.id,
			"",
		),
			await fetchDriverVehicleByDriverVehicleId({
				driver_vehicle_id:
					userStore.getShiftSchedule()?.driver_vehicle_id,
			});
		await delay(500);
	} catch (e) {
		throw new Error("error on mounted chooseasset", { cause: e });
	} finally {
		loadingAssets.value = false;
	}
});

//todo: properly integrate qr scanner

// const handleQRCodeScanning = async () => {
// 	qrListner.value = document.addEventListener("keypress", ($event) => {
// 		if ($event.key === "Enter") {
// 			const qrCodeSelectedAsset = orderStore.getCurrentOrderAssets.find(
// 				(asset) => asset.customer_asset.id === qrCodeAssetSearch.value,
// 			);
// 			if (!qrCodeSelectedAsset) {
// 				$toast("Incorrect QR code scanned", TYPE.ERROR);
// 			} else {
// 				startDispensingOnAsset(qrCodeSelectedAsset, true);
// 			}
// 			qrCodeAssetSearch.value = "";
// 		} else {
// 			qrCodeAssetSearch.value += $event.key;
// 		}
// 	});
// }

//end-of-script
</script>

<template>
	<div>
		<div class="flex flex-col justify-center space-y-4">
			<!-- heading and buttons -->
			<div class="flex flex-col justify-center items-center gap-y-4 w-full px-4 mt-4">
				<!-- SEARCH -->
				<OrderCard />
				<div
					class="w-full mx-auto relative flex items-center justify-between input p-4">
					<input
						type="text"
						name=""
						id=""
						class="w-full focus-visible:outline-0 !bg-white"
						placeholder="Search"
						v-model="searchQuery" />
					<Search alt="Search" class="text-gray-400" />
				</div>
			</div>

			<!-- list of ordered assets -->
			<Spinner
				v-if="loadingAssets"
				:color="'green-spin'"
				:size="'spinner-lg'"
				class="container-center" />
			<div
				v-if="!loadingAssets"
				class="w-full flex flex-col space-y-0 overflow-auto"
				:class="`${
					showProceed
						? `max-h-[calc(100vh-24.5rem)]`
						: `max-h-[calc(100vh-27.5rem)]`
				}`">
				<!-- card -->
				<div class="w-11/12 self-center gap-y-4">
					<div v-if="orderStore.getCurrentOrderAssets?.length">
						<AssetCard
							v-for="asset in orderStore.getCurrentOrderAssets"
							:key="asset?.id"
							:asset="asset" />
					</div>
					<p v-else>No asset found</p>
				</div>
			</div>

			<div
				v-if="!loadingAssets"
				class="w-full absolute bottom-8 left-0 container-center flex flex-col space-y-2">
				<!-- <button
				@click.prevent="qrCodeModal = true"
				class="rounded-full bg-black p-3 shadow-xl shadow-gray-600 mb-1"
				v-if="
					!orderStore.getCurrentOrder?.is_done_locally &&
					!orderStore.getCurrentOrder?.is_enable_rfid_flow
				">
				<img :src="QrScan" alt="QrScan" class="invert h-9 w-9" />
			</button> -->
				<button
					:disabled="!showProceed"
					@click="proceed"
					class="w-11/12 btn btn-green">
					Proceed
				</button>
				<button
					v-if="allAssetsNotDispensed"
					@click.prevent="openCancelOrder"
					class="btn btn-red !btn-md text-lg h-full w-11/12">
					Cancel Request
				</button>
			</div>
		</div>

		<CancelReason
			:cancelling="cancelling"
			:close-cancel-reason-modal="closeCancelReasonModal" />

		<!-- <ScanQrCodeModal
		:modal="qrCodeModal"
		:close-modal="() => (qrCodeModal = false)"
		:get-id="(id: string) => getIdFromScanner(id)" /> -->
	</div>
</template>

<style scoped>
.asset[disabled] {
	@apply !text-emerald-600 !bg-white !border;
}
</style>
