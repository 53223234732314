<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import ReadingInput from "@/components/common/ReadingInput.vue";
import AssetDispenseDetails from "@/components/steps/AssetDispenseDetails.vue";

import { useStepStore } from "@/store";

type Props = {
	chooseImage: (blob: File) => void;
	removeImage?: () => void;
	nameOne?: string;
	nameTwo?: string;
	readingOneTop?: boolean;
	readingTwoTop?: boolean;
	readingOneBottom?: boolean;
	readingTwoBottom?: boolean;
	button?: boolean;
	getReading?: (reading1: number, reading2?: number) => void;
};

const props = defineProps<Props>();
const stepStore = useStepStore();
const router = useRouter();

const file = ref<File | null>();
const imageSrc = ref<string>("");
const readingOne = ref<number>(0);
const readingTwo = ref<number>(0);
const clickimage = ref<HTMLDivElement | null>();

function clickImageButton(e: Event) {
	if (e.target) {
		clickimage.value?.click();
	}
}

const clickImage = async (e: Event) => {
	if (imageSrc.value) {
		imageSrc.value = "";
	}
	file.value = (e.target as HTMLInputElement).files?.[0] ?? null;
	const blob = file.value;

	if (file.value) {
		imageSrc.value = URL.createObjectURL(blob!);
	} else {
		imageSrc.value = "";
	}

	props.chooseImage(file.value!);
};

function readInput(name: string, reading: number) {
	if (name === "Indent" && reading) {
		readingOne.value = reading;
	} else if (name === "filled" && reading) {
		readingTwo.value = reading;
	}
	if (name === "Quantity Dispensed" && reading) {
		readingOne.value = reading;
	} else if (name === "Batch Code" && reading) {
		readingTwo.value = reading;
	} else {
		readingOne.value = reading;
	}
	props.getReading
		? props.getReading(readingOne.value, readingTwo.value)
		: null;
}

onMounted(() => {
	if (
		stepStore.currentStepReadingOne ||
		stepStore.currentStepReadingTwo ||
		(stepStore.currentStepImage && stepStore.currentStepImageFile)
	) {
		if (stepStore.stepDetailsRemoved) {
			imageSrc.value = "";
			readingOne.value = 0;
			readingTwo.value = 0;
			stepStore.saveCurrentStepDetails({
				readingOne: readingOne.value,
				readingTwo: readingTwo.value,
				imageSrc: imageSrc.value,
				imageSrcFile: null,
			});
		} else {
			imageSrc.value = stepStore.currentStepImage;
			readingOne.value = stepStore.currentStepReadingOne;
			readingTwo.value = stepStore.currentStepReadingTwo;
		}
		props.chooseImage(stepStore.currentStepImageFile);
		if (!router.currentRoute.value.path.includes("/challan")) {
			if (router.currentRoute.value.path.includes("/indent")) {
				props.getReading
					? props.getReading(
							stepStore.currentStepReadingOne,
							stepStore.currentStepReadingTwo,
					  )
					: null;
			} else {
				props.getReading
					? props.getReading(stepStore.currentStepReadingOne)
					: null;
			}
		}
	}
});

const hasAssetDetailsRoute = [
	'/totalizer-after-manual',
	'/totalizer-before-manual',
];

onUnmounted(() => {
	stepStore.saveCurrentStepDetails({
		readingOne: readingOne.value,
		readingTwo: readingTwo.value,
		imageSrc: imageSrc.value,
		imageSrcFile: file.value,
	});
});
</script>

<template>
	<div
		class="w-full flex flex-col space-y-6 items-center justify-center">
		<AssetDispenseDetails
			class="pt-2"
			v-if="
				hasAssetDetailsRoute.includes(
					router.currentRoute.value.fullPath,
				)
			" />
		<div class="w-full flex flex-col justify-between items-center">
			<ReadingInput
				:name="(nameOne as string)"
				:reading="readingOne"
				v-if="readingOneTop"
				:read-input="readInput" />
			<ReadingInput
				:name="(nameTwo as string)"
				:reading="readingTwo"
				v-if="readingTwoTop"
				:read-input="readInput" />
		</div>
		<p
			v-if="readingOneBottom"
			class="capitalize font-bold place-self-start -mb-6">
			{{ nameOne }} details
		</p>
		<div class="flex flex-col w-full">
			<div
				class="flex flex-col w-full font-bold -mt-6"
				v-if="readingOneBottom">
				<ReadingInput
					:name="(nameOne as string)"
					:reading="readingOne"
					v-if="readingOneBottom"
					:read-input="readInput" />
				<ReadingInput
					:name="(nameTwo as string)"
					:reading="readingTwo"
					v-if="readingTwoBottom"
					:read-input="readInput" />
			</div>
			<label
				v-if="button"
				@click.prevent="clickImageButton"
				name="clickimage"
				class="btn btn-green w-full"
				>Upload Image</label
			>
			<input
				@change="clickImage"
				name="upload"
				type="file"
				id="img-challan"
				placeholder="upload"
				class="text-base"
				accept="image/*"
				capture="environment"
				ref="clickimage"
				hidden />
		</div>
	</div>
</template>

<style scoped></style>
