import { defineStore } from "pinia";
import { ref } from "vue";
import { ConfirmationResult } from "firebase/auth";

export const useAuthStore = defineStore("authStore", () => {
	//state
	const phoneNumber = ref<string>("");
	const showOTPVerificationModal = ref<boolean>(false);
	const isOTPVerified = ref<boolean>(false);
	const verificationStarted = ref<boolean>(false);
	const otpVerificationError = ref<string>("");
	const loginConfirmationResult = ref<ConfirmationResult>();
	const showSignInLoader = ref<boolean>(false);

	//loading states
	const showOTPSentLoader = ref<boolean>(true);

	//actions
	const setPhoneNumber = (phone: string) => {
		phoneNumber.value = phone;
	};
	const toggleOTPVerificationModal = (state: boolean) => {
		showOTPVerificationModal.value = state;
	};
	const setIsOTPVerified = (state: boolean) => {
		isOTPVerified.value = state;
	};

	const toggleShowOTPSentLoader = (state: boolean) => {
		showOTPSentLoader.value = state;
	};

	const toggleVerificationStarted = (state: boolean) => {
		verificationStarted.value = state;
	};
	const setOTPVerificationError = (errorMessage: string) => {
		otpVerificationError.value = errorMessage;
	};
	const setLoginConfirmationResult = (
		confirmationResult: ConfirmationResult,
	) => {
		loginConfirmationResult.value = confirmationResult;
	};
	const toggleSignInLoader = (state: boolean) => {
		showSignInLoader.value = state;
	};

	return {
		phoneNumber,
		showOTPVerificationModal,
		showOTPSentLoader,
		isOTPVerified,
		verificationStarted,
		otpVerificationError,
		loginConfirmationResult,
		showSignInLoader,
		setPhoneNumber,
		toggleOTPVerificationModal,
		toggleShowOTPSentLoader,
		setIsOTPVerified,
		toggleVerificationStarted,
		setOTPVerificationError,
		setLoginConfirmationResult,
		toggleSignInLoader,
	};
});

export default useAuthStore;
