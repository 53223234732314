import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";

const toast = useToast();
// type Props = {
//     message: string;
//     type: string;
// };
// const props = defineProps<Props>();
type toastPositionsOptions =
	| "top"
	| "bottom"
	| "top-right"
	| "bottom-right"
	| "top-left"
	| "bottom-left";
type toastTypeOptions =
	| "success"
	| "info"
	| "warning"
	| "error"
	| "default";
const $toast = (
	message: string,
	type: toastTypeOptions,
	pos: toastPositionsOptions,
	queue: boolean = false
) =>
	toast.open({
		position: pos,
		duration: 2000,
		dismissible: true,
		message: message,
		type: type,
		queue,
	});

export default $toast;
