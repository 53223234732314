import {
	CreateDeliveryInvoiceInput,
	GetCustomerAssetsByOrderIdOrNameQuery,
	GetTaskByTaskIdQuery,
	Organization_Configuration,
	Task_State_Enum,
	Vehicle_Inventory_Transaction_Logs_Insert_Input,
} from "@/sdk";
import client from "./client";
import { useUserStore } from "@/store/userStore";
import { useOrderStore } from "@/store/orderStore";
import $toast from "@/utils/toast";
import { TYPE } from "vue-toastification";

export const fetchIncompleteDriverOrder = async (): Promise<
	any[]
> => {
	const userStore = useUserStore();
	const orderStore = useOrderStore();
	try {
		const shiftSchedule = userStore.getShiftSchedule();
		const task = (
			await (
				await client
			)?.getTasksByStateAndDriverVehicleID({
				limit: 1,
				offset: 0,
				state: [Task_State_Enum.Dispensing],
				driver_vehicle_id: shiftSchedule?.driver_vehicle_id,
			})
		)?.task[0];
		if (task) {
			orderStore.setIncompleteOrder(task);
			updateCompletedAssetIDs(
				task.customer_order?.customer_order_customer_assets || [],
			);

			const totalQuantityDispensed =
				task?.customer_order?.customer_order_customer_assets.reduce(
					(total: number, obj: any) => {
						if (
							obj.quantity_dispensed !== null ||
							obj.quantity_dispensed !== undefined
						) {
							return total + obj.quantity_dispensed!;
						}
					},
					0,
				);
			if (totalQuantityDispensed) {
				orderStore.setFuelDispensedTillNow(totalQuantityDispensed);
			}
			const customerOrganizationConfig = await (
				await client
			).fetchCustomerOrganizationConfiguration({
				organization_id:
					task.customer_order?.organization_user?.organization_id,
			});
			orderStore.setIsCollectOdometerReading(
				customerOrganizationConfig?.organization_configuration as Organization_Configuration[],
			);
			orderStore.setQuantityToBeDispensed(
				task?.customer_order?.customer_order_items[0].qty,
			);
			return [task];
		} else {
			orderStore.resetCompletedAssets();
			orderStore.setIncompleteOrder(null);
		}
		orderStore.resetCompletedAssets();
		return [];
	} catch (error) {
		console.error(error);
		throw new Error("Error fetching incomplete driver order");
	}
};

export const fetchUpcomingDriverOrder = async (): Promise<any[]> => {
	const orderStore = useOrderStore();
	const userStore = useUserStore();
	try {
		const shiftSchedule = userStore.getShiftSchedule();
		const response = await (
			await client
		)?.getTasksByNotInStateAndDriverVehicleID({
			offset: 0,
			not_state: [
				Task_State_Enum.Cancelled,
				Task_State_Enum.CancellationRequested,
				Task_State_Enum.Delivered,
				Task_State_Enum.Rejected,
				Task_State_Enum.Schedule,
				Task_State_Enum.Rescheduled,
				Task_State_Enum.Open,
				Task_State_Enum.Dispensing,
			],
			driver_vehicle_id: shiftSchedule?.driver_vehicle_id,
		});

		orderStore.setUpcomingOrders(response.task);

		return response?.task;
	} catch (error) {
		throw new Error("Error fetching orders");
	}
};

export const fetchCustomerAssetsByOrderID = async (
	orderId: string,
	searchTerm: string,
): Promise<
	GetCustomerAssetsByOrderIdOrNameQuery["customer_order_customer_asset"]
> => {
	const orderStore = useOrderStore();
	try {
		const customerAssets = (
			await (
				await client
			)?.getCustomerAssetsByOrderIDOrName({
				customer_order_id: orderId,
				searchKey: `%${searchTerm}%`,
			})
		)?.customer_order_customer_asset;
		orderStore.setCurrentOrderAssets(customerAssets);
		return customerAssets;
	} catch (error) {
		throw new Error("Error fetching customer assets");
	}
};

export const fetchTaskByTaskID = async (
	taskID: string,
): Promise<GetTaskByTaskIdQuery["task"][0] | null> => {
	const orderStore = useOrderStore();
	try {
		const task = (
			await (
				await client
			)?.getTaskByTaskID({
				id: taskID,
			})
		)?.task[0];
		if (task) {
			const customerOrganizationConfig = await (
				await client
			).fetchCustomerOrganizationConfiguration({
				organization_id:
					task.customer_order?.organization_user?.organization_id,
			});
			orderStore.setIsCollectOdometerReading(
				customerOrganizationConfig?.organization_configuration as Organization_Configuration[],
			);
			orderStore.setCurrentOrder(task);
			orderStore.setTotalQty(
				task.customer_order?.customer_order_items[0].qty,
			);
			updateCompletedAssetIDs(
				task.customer_order?.customer_order_customer_assets || [],
			);
			const totalQuantityDispensed =
				task?.customer_order?.customer_order_customer_assets.reduce(
					(total: number, obj: any) => {
						if (
							obj.quantity_dispensed !== null ||
							obj.quantity_dispensed !== undefined
						) {
							return total + obj.quantity_dispensed!;
						}
					},
					0,
				);
			if (totalQuantityDispensed) {
				orderStore.setFuelDispensedTillNow(totalQuantityDispensed);
			}
			orderStore.setQuantityToBeDispensed(
				task.customer_order?.customer_order_items[0]?.qty,
			);
			return task;
		}
		return null;
	} catch (error) {
		throw new Error("Error fetching task", {
			cause: error,
		});
	}
};

const updateCompletedAssetIDs = async (
	customer_order_customer_assets: any[],
) => {
	const orderStore = useOrderStore();
	let dispensedQtySum = 0;
	customer_order_customer_assets.forEach((asset) => {
		if (
			asset.quantity_dispensed !== null &&
			asset.quantity_dispensed !== undefined &&
			asset.quantity_dispensed !== 0
		) {
			dispensedQtySum += asset.quantity_dispensed;
			orderStore.getCompletedAssetIds.indexOf(
				asset.customer_asset.id,
			) === -1 &&
				orderStore.addCompletedAssetId(asset.customer_asset.id);
		}
	});
	orderStore.setDispensedQuantity(dispensedQtySum);
};

export const updateCustomerOrderCustomerAssetQty = async (
	customerOrderId: string,
	customerAssetId: string,
	quantityDispensed: number,
) => {
	try {
		// First, getting if there is existing dispensed quantity for the same customer asset in the order
		const existingDispensedQty = (
			await (
				await client
			)?.getCustomerOrderCustomerAssetQuantity({
				customerAssetId,
				customerOrderId,
			})
		)?.customer_order_customer_asset[0]?.quantity_dispensed;
		const finalDispensedQuantity =
			(existingDispensedQty &&
			typeof existingDispensedQty === "number"
				? Number(existingDispensedQty)
				: 0) + quantityDispensed;
		await (
			await client
		)?.updateCustomerOrderCustomerAssetQty({
			customerAssetId,
			customerOrderId,
			qty: Number(finalDispensedQuantity.toFixed(2)),
		});
	} catch (error) {
		throw new Error("Error updating customer order customer asset");
	}
};

export const fetchLastOdometerReading = async (
	customerAssetId: string,
) => {
	try {
		const res = await (
			await client
		)?.getLastOdometerReadingForAsset({
			customer_asset_id: customerAssetId,
		});
		return res?.task_value[0];
	} catch (error) {
		throw new Error("Failed to fetch odometer reading");
	}
};

export const fetchHealthAndSafetyChecks = async () => {
	try {
		return await (await client)?.fetchSafetyChecklist();
	} catch (error) {
		throw new Error("Failed to fetch health and safety checks", {
			cause: error,
		});
	}
};

export const createDeliveryInvoice = async (
	invoiceInput: CreateDeliveryInvoiceInput,
) => {
	try {
		return await (
			await client
		)?.createDeliveryInvoice({ object: invoiceInput });
	} catch (err) {
		$toast(
			"Failed while creating invoice. Please contact control center",
			TYPE.ERROR,
		);
		throw new Error("Error Creating Delivery Invoice");
	}
};

export const markOrderCompleted = async (id: string) => {
	try {
		await (
			await client
		)?.changeTaskState({
			id: id,
			state: Task_State_Enum.Delivered,
		});
		return true;
	} catch (err) {
		alert("Error marking delivery as Delivered. Please try again.");
		throw new Error("Error completing order task update");
	}
};

export const fetchDriverVehicleByDriverVehicleId = async (args: {
	driver_vehicle_id: string;
}) => {
	const orderStore = useOrderStore();
	try {
		const response = await (
			await client
		).getDriverVehicleDetailsById({ ...args });

		orderStore.setDriverVehicleDetails(
			response?.driver_vehicle_by_pk?.vehicle,
		);
		return response?.driver_vehicle_by_pk;
	} catch (err) {
		throw new Error("Error fetching driver vehicle by id", {
			cause: err,
		});
	}
};

export const addTransactionLogs = async (
	object: Vehicle_Inventory_Transaction_Logs_Insert_Input,
) => {
	try {
		const response = await (
			await client
		).addingVehicleInventoryTransactionLogs({ object: object });
		return response;
	} catch (err) {
		throw new Error("Error adding logs", {
			cause: err,
		});
	}
};
