//dependencies
import { defineStore } from "pinia";
import { ref, computed } from "vue";

//imports
import vehicle from "../assets/truck-droplet.svg?url";
import tank from "../assets/fuel-tank.svg?url";
import other from "../assets/machinery.svg?url";
import diesel from "../assets/fuel-pump-diesel-fill.svg?url";
import genset from "../assets/functiongenerator.svg?url";

export const useStepStore = defineStore("stepStore", () => {
	//state
	const selectedAssetType = ref<string>("vehicle");
	const assetCapacity = ref<string>("");
	const assetName = ref<string>("");
	const assetId = ref<string>("");

	const showAddVehicle = ref<boolean>(false);
	const showSearchAllAssets = ref<boolean>(false);
	const showHealthSafety = ref<boolean>(true);
	const selectedAssetForDispense = ref<any>({});
	const allselectedAssetsForDispense: any[] = [];
	const assetsFilledList: any[] = [];
	const isCustomerOTPVerified = ref<boolean>(false);
	const stepDetailsRemoved = ref<boolean>(false);

	const currentStepReadingOne = ref<any>();
	const currentStepReadingTwo = ref<any>();
	const currentStepImage = ref<any>();
	const currentStepImageFile = ref<any>();

	const allvehicleProps = ref<any[]>([
		{
			label: "My asset 1",
			value: "My asset 1",
			tankCapacity: "5000",
			id: "id-1",
			type: "vehicle",
			icon: vehicle,
		},
		{
			label: "My asset 2",
			value: "My asset 2",
			tankCapacity: "3000",
			id: "id-2",
			type: "genset",
			icon: genset,
		},
		{
			label: "My asset 3",
			value: "My asset 3",
			tankCapacity: "2000",
			id: "id-3",
			type: "diesel on tap",
			icon: diesel,
		},
		{
			label: "My asset 4",
			value: "My asset 4",
			tankCapacity: "10000",
			id: "id-4",
			type: "tank",
			icon: tank,
		},
		{
			label: "My asset 5",
			value: "My asset 5",
			tankCapacity: "1000",
			id: "id-5",
			type: "tank",
			icon: tank,
		},
		{
			label: "My asset 6",
			value: "My asset 6",
			tankCapacity: "5000",
			id: "id-6",
			type: "diesel on tank",
			icon: diesel,
		},
		{
			label: "My asset 7",
			value: "My asset 7",
			tankCapacity: "2000",
			id: "id-7",
			type: "tank",
			icon: tank,
		},
		{
			label: "My asset 8",
			value: "My asset 8",
			tankCapacity: "6000",
			id: "id-8",
			type: "genset",
			icon: genset,
		},
		{
			label: "My asset 9",
			value: "My asset 9",
			tankCapacity: "7000",
			id: "id-9",
			type: "vehicle",
			icon: vehicle,
		},
	]);

	const vehicleProps = ref<any>([
		{
			label: "My asset 1",
			value: "My asset 1",
			tankCapacity: "5000",
			id: "id-1",
			type: "vehicle",
			icon: vehicle,
		},
		{
			label: "My asset 2",
			value: "My asset 2",
			tankCapacity: "3000",
			id: "id-2",
			type: "genset",
			icon: genset,
		},
		{
			label: "My asset 3",
			value: "My asset 3",
			tankCapacity: "2000",
			id: "id-3",
			type: "diesel on tap",
			icon: diesel,
		},
		{
			label: "My asset 4",
			value: "My asset 4",
			tankCapacity: "1000",
			id: "id-4",
			type: "tank",
			icon: tank,
		},
		{
			label: "My asset 5",
			value: "My asset 5",
			tankCapacity: "1000",
			id: "id-5",
			type: "other",
			icon: other,
		},
	]);

	const assetList = [
		{
			value: "vehicle",
		},
		{
			value: "genset",
		},
		{
			value: "tank",
		},
		{
			value: "diesel on tap",
		},
		{
			value: "other",
		},
	];

	//actions
	function toggleHealthSafetyModal(value: boolean) {
		showHealthSafety.value = value;
	}

	function setCustomerOTPVerified(value: boolean) {
		isCustomerOTPVerified.value = value;
	}
	const addOrder = (asset: any) => {
		const oldState = [...vehicleProps.value];
		const id = asset.id;
		// const icon = asset.icon;
		const name = asset.customer_asset.name;
		const type = asset.customer_asset.description;
		const tankCapacity = asset.customer_asset.capacity;
		const newAsset = {
			label: name,
			value: name,
			title: name,
			tankCapacity: tankCapacity,
			id: id,
			type: type,
			// icon: icon,
		};

		oldState.unshift(newAsset);

		vehicleProps.value = [...oldState];

		showSearchAllAssets.value = false;
	};

	function getIcon(type: any) {
		if (type.value == "vehicle") {
			return vehicle;
		} else if (type.value == "tank") {
			return tank;
		} else if (type.value == "other") {
			return other;
		} else if (type.value == "diesel on tap") {
			return diesel;
		} else {
			return genset;
		}
	}

	const addNewVehicle = () => {
		if (assetCapacity.value && assetName.value && assetId.value) {
			const oldStateAll = [...allvehicleProps.value];

			const newAsset = {
				label: assetName.value,
				value: assetName.value,
				title: assetName.value,
				tankCapacity: assetCapacity.value,
				id: assetId.value,
				type: selectedAssetType.value,
				icon: getIcon(selectedAssetType),
			};

			oldStateAll.unshift(newAsset);

			allvehicleProps.value = [...oldStateAll];

			showAddVehicle.value = false;
		} else {
			alert("Please fill all the details");
		}
	};

	const setSelectedAssetForDispense = (asset: any) => {
		selectedAssetForDispense.value = asset;
		allselectedAssetsForDispense.push(selectedAssetForDispense.value);
	};

	const setAssetForDispense = (asset: any) => {
		setSelectedAssetForDispense(asset);
	};

	function addAssetFilled() {
		assetsFilledList.push(selectedAssetForDispense.value);
	}
	const searchAllQuery = ref("");
	const searchedAllAssets = computed(() => {
		return allvehicleProps?.value?.filter((asset: any) => {
			return (
				asset.value
					?.toLowerCase()
					.indexOf(searchAllQuery.value.toLowerCase()) != -1
			);
		});
	});

	const searchQuery = ref("");
	const searchedAssets = computed(() => {
		return vehicleProps.value?.filter((asset: any) => {
			return (
				asset.value
					?.toLowerCase()
					.indexOf(searchQuery.value.toLowerCase()) != -1
			);
		});
	});

	const setShowSearchAllAssets = (state: boolean) => {
		showSearchAllAssets.value = state;
	};

	const saveCurrentStepDetails = (details: any) => {
		currentStepReadingOne.value = details.readingOne;
		currentStepReadingTwo.value = details.readingTwo;
		currentStepImage.value = details.imageSrc;
		currentStepImageFile.value = details.imageSrcFile;
	};

	const removeCurrentStepDetails = (state: boolean) => {
		stepDetailsRemoved.value = state;
	};

	return {
		showHealthSafety,
		toggleHealthSafetyModal,
		selectedAssetForDispense,
		setSelectedAssetForDispense,
		allselectedAssetsForDispense,
		allvehicleProps,
		vehicleProps,
		assetList,
		addOrder,
		addNewVehicle,
		setAssetForDispense,
		selectedAssetType,
		assetCapacity,
		assetName,
		assetId,
		showAddVehicle,
		showSearchAllAssets,
		setShowSearchAllAssets,
		searchAllQuery,
		searchedAllAssets,
		searchQuery,
		searchedAssets,
		assetsFilledList,
		stepDetailsRemoved,
		addAssetFilled,
		setCustomerOTPVerified,
		isCustomerOTPVerified,
		saveCurrentStepDetails,
		currentStepReadingOne,
		currentStepReadingTwo,
		currentStepImage,
		currentStepImageFile,
		removeCurrentStepDetails,
	};
});

export default useStepStore;
