<script setup lang="ts">
import { ref } from "vue";
import { useStepStore } from "@/store";
//actions
import { restrictInputFunction } from "@/services/general";

const stepStore = useStepStore();

type Props = {
	name: string;
	reading: number;
	readInput: (name: string, reading: number) => void;
};
const props = defineProps<Props>();

const readingVal = ref<number>();

function readingInput() {
	props.readInput(props.name, readingVal.value!);
}
</script>

<template>
	<div class="w-full">
		<p class="font-bold pt-4">
			{{
				`${
					name === "Indent"
						? `Indent Reading`
						: name === "filled"
						? `Filled Quantity`
						: ``
				}`
			}}
		</p>
		<input
			pattern="[0-9*]"
			inputmode="decimal"
			@wheel="(event: any) => event.preventDefault()"
			@keydown="restrictInputFunction"
			@keyup="readingInput"
			v-model="readingVal"
			type="number"
			class="input border-gray-200"
			:class="stepStore.stepDetailsRemoved ? '' : '!text-gray-800'"
 />
	</div>
</template>

<style scoped></style>
