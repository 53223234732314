<script setup lang="ts">
// dependencies
import { useRoute } from "vue-router";
import Menu from "@/assets/menu.svg?component"

// services
import { logout } from "@/services/auth.service";

const route = useRoute();
</script>
<template>
	<div className="sticky navbar top-0 bg-emerald-600 w-full h-10 z-50">
		<div className="navbar-start">
			<div v-if="route.name === 'Dashboard'" className="dropdown">
				<div
					tabIndex="{0}"
					role="button"
					className="btn btn-ghost btn-circle">
					<Menu class="scale-[1.4]" />
				</div>
				<ul
					tabIndex="{0}"
					className="menu menu-sm dropdown-content bg-white rounded-box z-[1] mt-3 w-52 p-2 shadow">
					<li @click="logout"><a>Logout</a></li>
				</ul>
			</div>
		</div>
		<div className="navbar-center">
			<span className="btn btn-ghost text-xl">{{ route.name }}</span>
		</div>
		<div className="navbar-end"></div>
	</div>
</template>

<style scoped>
.menu a:focus,
.menu a:active {
    outline: none;
    background-color: transparent;
}
.menu a:hover {
    background-color: transparent;
}
</style>
