import { TYPE, useToast } from "vue-toastification";

const toast = useToast();

const $toast = (message: string, type: TYPE) => {
	toast(message, {type: type})
}


export default $toast;
