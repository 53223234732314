<script setup lang="ts">
import { getDriverShiftSchedule } from "@/services/user.service";
import router from "@/router";
import $toast from "@/utils/toast";
import { TYPE } from "vue-toastification";
import { ref } from "vue";
import { logout } from "@/services/auth.service";

const checkScheduleLabel = ref("Check again");
const checkScheduleLoader = ref(false);

const logoutUser = () => {
	logout();
	router.replace("/");
	location.reload();
};

const shiftScheduleFetch = async () => {
	try {
		checkScheduleLoader.value = true;
		checkScheduleLabel.value = "Checking...";
		const driverShift = await getDriverShiftSchedule();
		if (driverShift) {
			$toast(
				"Shift schedule found. Redirecting to dashboard",
				TYPE.SUCCESS,
			);
			await router.push("/dashboard");
		} else {
			$toast(
				"Shift schedule not found. Contact control center",
				TYPE.INFO,
			);
		}
	} catch (error) {
		$toast(
			"Failed to check shift schedule. Try again later",
			TYPE.ERROR,
		);
		console.error(error);
	} finally {
		checkScheduleLoader.value = false;
		checkScheduleLabel.value = "Check again";
	}
};
</script>
<template>
	<div class="drawer">
		<div class="bg-emerald-600 h-full p-4 flex">
			<div class="w-full text-white text-xl">
				<div class="flex items-center">
					<span>Hii..Buddy</span>
				</div>
			</div>
			<div class="w-full text-white text-xl">
				<div class="flex items-center justify-end">
					<span @click="logoutUser">Logout</span>
				</div>
			</div>
		</div>

		<div class="w-full h-screen flex items-center justify-center p-2">
			<div
				class="flex flex-col justify-center items-center bg-white rounded-lg p-4">
				<img
					src="https://res.cloudinary.com/dxmldil1b/image/upload/v1711004939/india/order_assignment_upexph.png"
					alt="No active shift"
					class="mx-auto mb-4" />
				<h1 class="text-2xl fbt-heading">No active shift!</h1>
				<button
					class="fb-btn fb-btn-success w-[200px] rounded-lg h-[50px] mt-4 bg-emerald-600 text-white flex items-center justify-center"
					@click="shiftScheduleFetch">
					<span
						v-if="checkScheduleLoader"
						class="loading loading-spinner loading-sm"></span>
					{{ checkScheduleLabel }}
				</button>
			</div>
		</div>
	</div>
</template>
