<script setup lang="ts">
//dependencies
import { ref, onMounted } from "vue";

//store
import { useUserStore } from "../store";

//imports
import EyeIcon from "../assets/eye.svg?component";
import EyeOffIcon from "../assets/eye-off.svg?component";
import Spinner from "../components/common/Spinner.vue";
import FuelBuddy from "../assets/fuelbuddy-logo.svg?component";

//services
import {
	login,
	startTokenRefreshTimer,
	// signOutUser
} from "@/services/auth.service";
import { getCurrentUser } from "@/firebase/index";
import { getDriverShiftSchedule } from "@/services/user.service";
import router from "@/router";
import $toast from "@/utils/toast";
import { TYPE } from "vue-toastification";

const showPassword = ref<boolean>(false);
const email = ref<string>("");
const password = ref<string>("");
const authLoading = ref<boolean>(true);
const isLoggedIn = ref<boolean>(false);
const loginLoading = ref<boolean>(false);

const userStore = useUserStore();

const delay = (ms: number) =>
	new Promise((resolve) => setTimeout(resolve, ms));

const getShiftSchedule = async () => {
	try {
		const shiftSchedule = await getDriverShiftSchedule();
		if (!shiftSchedule) {
			router.replace("/no-shift");
		} else {
			router.replace("/dashboard");
		}
	} catch (error) {
		console.error(error);
	}
	const maxRetries = 3;
	const retryDelay = 10000;

	for (let attempt = 1; attempt <= maxRetries; attempt++) {
		try {
			const shiftSchedule = await getDriverShiftSchedule();
			if (!shiftSchedule) {
				router.replace("/no-shift");
			} else {
				router.replace("/dashboard");
			}
			return;
		} catch (error) {
			if (attempt === maxRetries) {
				console.error("Max retries reached. Error:", error);
			} else {
				console.warn(
					`Attempt ${attempt} failed. Retrying in ${retryDelay}ms...`,
				);
				await delay(retryDelay);
			}
		}
	}
};

const loginSubmit = async () => {
	if (loginLoading.value) return;
	loginLoading.value = true;
	try {
		await login(email.value, password.value);
		await delay(1000);
		window.location.reload();
	} catch (error) {
		$toast((error as Error).message as string, TYPE.ERROR);
		console.error(error);
	} finally {
		loginLoading.value = false;
	}
};

const togglePassVisibility = () => {
	showPassword.value = !showPassword.value;
};

onMounted(async () => {
	try {
		const user = await getCurrentUser();
		if (!user) {
			isLoggedIn.value = false;
			authLoading.value = false;
			return;
		}
		startTokenRefreshTimer();
		isLoggedIn.value = true;
		userStore.setUser(user);
		await delay(1000);
		await getShiftSchedule();
	} catch (error) {
		authLoading.value = false;
	}
});
//end-of-script
</script>

<template>
	<div
		class="relative w-full flex flex-col items-center justify-center min-h-screen">
		<FuelBuddy class="h-10 absolute top-6" />
		<div v-if="!authLoading && !isLoggedIn" class="mb-10">
			<h1>Welcome</h1>
			<p class="text-gray-500 text-center">Login to continue</p>
		</div>
		<div v-if="authLoading">
			<Spinner :size="'spinner-lg'" :color="'green-spin'" />
		</div>
		<form
			v-if="!authLoading && !isLoggedIn"
			class="w-full px-10 flex flex-col items-center gap-y-8"
			@submit.prevent="loginSubmit">
			<!-- email -->
			<div class="flex flex-col w-full h-[70px]">
				<input
					class="w-full input !bg-white h-12 pl-2 rounded-md text-lg focus-visible:outline-0"
					v-model="email"
					name="email"
					type="email"
					placeholder="Email"
					required />
			</div>
			<!-- pwd -->
			<div class="w-full relative">
				<input
					name="password"
					v-model="password"
					:type="showPassword ? 'text' : 'password'"
					class="w-full input !bg-white"
					placeholder="Password"
					required
					autocomplete="off" />
				<button
					type="button"
					class="absolute right-2 top-3"
					@click="togglePassVisibility">
					<EyeIcon v-if="!showPassword" />
					<EyeOffIcon v-if="showPassword" />
				</button>

				<!-- <router-link
          to="forgot-password"
          class="text-[0.65rem] text-green-600 mt-8 font-medium"
          >Forgot Password ?</router-link
        > -->
			</div>

			<!-- buttons -->
			<div class="w-full font-medium">
				<button type="submit" class="w-full btn btn-green mt-4">
					Login
					<div class="ml-4" v-if="loginLoading || authLoading">
						<Spinner :color="'white-spin'" :size="'spinner-md'" />
					</div>
				</button>
				<!-- <div class="divider w-10/12 mx-auto text-sm text-gray-400">OR</div>
        <button class="w-full btn btn-blue mt-4">
          <router-link to="/login-phone"> Use phone number </router-link>
        </button> -->
				<!-- <p class="text-xs mt-3">
          Not a member?
          <router-link to="register" class="text-green-600">
            Sign Up Now
          </router-link>
        </p> -->
			</div>
		</form>
	</div>
</template>

<style scoped></style>
