import { type User } from "firebase/auth";
import { defineStore } from "pinia";

export type UserStoreStateType = {
	user: null | User;
	shiftSchedule: any;
	authToken: string;
};

export const useUserStore = defineStore("userStore", () => {
	let user: null | User = null;
	let shiftSchedule: any = null;
	let authToken: string = "";

	const getUser = () => user;
	const getShiftSchedule = () => shiftSchedule;
	const getDriverDetails = () => shiftSchedule?.driver_vehicle?.user;
	const getVehicleDetails = () => shiftSchedule?.driver_vehicle?.vehicle;
	const getAuthToken = () => authToken;

	const setUser = (firebaseUser: User) => {
		user = firebaseUser;
	};

	const setShiftSchedule = (newShiftSchedule: any) => {
		shiftSchedule = newShiftSchedule;
	};

	const setAuthToken = (newAuthToken: string) => {
		authToken = newAuthToken;
	}

	return {
		getUser,
		getShiftSchedule,
		getDriverDetails,
		getVehicleDetails,
		getAuthToken,

		setUser,
		setShiftSchedule,
		setAuthToken,
	};
});
