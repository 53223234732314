<script setup lang="ts">
import Navbar from './components/common/Navbar.vue';
import { useRoute } from 'vue-router';
import { useLoaderStore } from './store';
import Spinner from './components/common/Spinner.vue';

const loaderStore = useLoaderStore();

const route = useRoute();
</script>

<template>
	<div
		class="w-screen h-screen flex flex-col items-stretch justify-stretch relative">
		<Navbar v-if="!route.meta.hideNavbar" />
		<RouterView class="flex-grow z-10" />
		<input
			type="checkbox"
			id="my-modal"
			class="modal-toggle"
			:checked="loaderStore.loaders.common" />
		<div class="modal">
			<div class="modal-box bg-white container-center rounded-md">
				<div class="text-lg flex items-center gap-x-3">
					<p>Please wait</p>
					<Spinner
						:size="'spinner-lg'"
						:color="'green-spin'"
						class="scale-[0.8]" />
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
