import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import * as Sentry from "@sentry/vue";
import router from "./router";
import "./style.css";
import App from "./App.vue";
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

// Create the Pinia store
const store = createPinia();
store.use(piniaPluginPersistedState);

// Create the Vue app
const app = createApp(App);

// Define Toast options
const toastOption: PluginOptions = {
  maxToasts: 5,
  newestOnTop: true,
  position: POSITION.TOP_CENTER,
  hideProgressBar: true,
  closeButton: false,
  timeout: 3000,
};

// Configure Sentry
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.browserProfilingIntegration(),
],  
  tracesSampleRate: 0.1,
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate

  beforeSend(event) {
    if (event.level === 'info') {
      return null;
    }
    return event;
  },
});

// Use the store, router, and toast plugin in the app
app.use(store);
app.use(router);
app.use(Toast, toastOption);

// Mount the app
app.mount("#app");