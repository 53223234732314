import { defineStore } from "pinia";
import { reactive } from "vue";

type Loaders = {
	orderHistory: boolean;
	image: boolean;
	common: boolean;
	todayOrders: boolean;
	dispenser: boolean;
};

type LoaderType =
	| "orderHistory"
	| "image"
	| "common"
	| "todayOrders"
	| "dispenser";

type Args = {
	type: LoaderType;
	state: boolean;
};

export const useLoaderStore = defineStore("loaderStore", () => {
	//loading states
	const loaders = reactive<Loaders>({
		orderHistory: true,
		image: false,
		common: false,
		todayOrders: true,
		dispenser: false,
	});

	//actions
	const toggleLoader = (args: Args) => {
		loaders[args.type] = args.state;
	};

	return {
		loaders,
		toggleLoader,
	};
});

export default useLoaderStore;
