import { defineStore } from "pinia";
import { ref } from "vue";
import { connectDispenser } from "@fuelbuddy/dispenser/dist/tsc/main";
import { IRfid } from "@fuelbuddy/dispenser/dist/tsc/lib/IRfid";
import { RfidFactory } from "@fuelbuddy/dispenser/dist/tsc/lib/RfidFactory";
import { useOrderStore } from "@/store";

import $toast from "@/utils/notification";

export const useRfidStore = defineStore("useRfidStore", () => {
	//state
	const socket = ref<WebSocket>();
	const rfid = ref<IRfid | null>();
	const dispenseQuantity = ref<any>();
	const intervalInstance = ref<any>();

	const rfidNotWorking = ref<boolean>(false);
	const rfidNotWorkingCounter = ref<number>(0);

	const connectAgainRfid = ref<boolean>(false);

	const rfidCounter = ref<number>(0);
	const rfidStatus = ref<any>();
	const rfidTagId = ref<any>();
	const rfidComment = ref<any>();

	const rfidReconnected = ref<number>(0);

	const toggleConnectAgain = (state: boolean) => {
		connectAgainRfid.value = state;
	};

	const setIntervalInstance = (instance: any) => {
		intervalInstance.value = instance;
	};

	const disconnectSocket = async () => {
		socket.value?.close();
		rfid.value = null;
	};

	const updateRfidReconnectedCount = () => {
		rfidReconnected.value++;
	};

	const setRfidNotWorking = (state: boolean) => {
		rfidNotWorking.value = state;
	};

	const initSocket = async () => {
		const orderStore = useOrderStore();

		if (rfidNotWorkingCounter.value > 1) {
			$toast("Reconnecting RFID socket ...", "error", "top");
		}

		try {
			socket.value = await connectDispenser(
				import.meta.env.VITE_RFID_IP,
			);
			socket.value.onclose = () => {
				toggleConnectAgain(true);
				window.clearInterval(intervalInstance.value);
				if (
					!orderStore.currentDriverOrder?.is_enable_rfid_flow ||
					!(
						localStorage.getItem(
							"proceed_after_dispense/" +
								orderStore.currentDriverOrder.id,
						) === "true"
					)
				) {
					$toast(
						"Closing rfid web socket. Please reconnect if required",
						"error",
						"top",
					);
				}
				rfidNotWorking.value = true;
				rfidNotWorkingCounter.value = 1;
				updateRfidReconnectedCount();
			};
			try {
				const response = await RfidFactory.initialize(socket.value);

				rfid.value = response;
				rfidNotWorking.value = false;
				if (rfidNotWorkingCounter.value > 1) {
					$toast("RFID Reconnected", "success", "top");
				}

				rfidNotWorkingCounter.value = 0;
				setRfidNotWorking(false);
				updateRfidReconnectedCount();
			} catch (err: any) {
				$toast("Error connecting to RFID", "error", "top");
				rfid.value = undefined;
				setRfidNotWorking(true);
				updateRfidReconnectedCount();
				throw new Error("Error connecting to RFID", { cause: err });
			}
			return socket.value;
		} catch (err) {
			toggleConnectAgain(true);
			updateRfidReconnectedCount();
			$toast(
				"Closing rfid web socket. Please reconnect or Raise request.",
				"error",
				"top",
			);
			rfidCounter.value = 0;
			setRfidNotWorking(true);
			throw new Error("Error connecting to RFID", { cause: err });
		}
	};

	// const timeoutPromise = new Promise((resolve, reject) => {
	// 	// Set a timeout for 10 seconds
	// 	setTimeout(async () => {
	// 		reject(
	// 			new Error("Command Timeout: The request took too long."),
	// 		);
	// 		alert(
	// 			"RFID is taking too long to response . Please reconnect it.",
	// 		);
	// 		await disconnectSocket();
	// 		updateRfidReconnectedCount();
	// 		setRfidNotWorking(true);
	// 	}, 30000);
	// });

	// for actions without values
	const callRfidAction = async (callback1: any, callback2: any) => {
		const orderStore = useOrderStore();
		if (rfid.value) {
			try {
				// const result = await Promise.race([
				// 	rfid.value?.execute(callback1, callback2),
				// 	timeoutPromise,
				// ]);
				const result = await rfid.value?.execute(
					callback1,
					callback2,
				);
				return result;
			} catch (err) {
				throw new Error("Error calling RFID action", { cause: err });
			}
		} else {
			if (orderStore?.currentDriverOrder?.is_enable_rfid_flow) {
				$toast(
					"RFID not connected. Please reconnect and try again",
					"error",
					"top",
				);
				rfidCounter.value = 0;
			}
		}
	};

	const readRFIDStatus = async () => {
		const response = await callRfidAction(
			rfid.value?.rfidStatus,
			rfid.value?.processRFIDresponse,
		);
		return response;
	};

	const getRfidLogs = async () => {
		if (rfid.value && rfid.value?.downloadLogs) {
			const response = rfid.value?.downloadLogs();
			return response;
		}
	};

	function setRfidCounter(value: any) {
		rfidCounter.value = rfidCounter.value + value;
	}

	function setRfidStatus(status: any) {
		rfidStatus.value = status;
	}

	function setRfidComment(comment: any) {
		rfidComment.value = comment;
	}

	function setRfidTagId(tag: any) {
		rfidTagId.value = tag;
	}

	return {
		rfid,
		socket,
		connectAgainRfid,
		dispenseQuantity,
		intervalInstance,
		setIntervalInstance,
		initSocket,
		disconnectSocket,
		rfidNotWorking,
		rfidNotWorkingCounter,
		rfidReconnected,
		updateRfidReconnectedCount,
		setRfidNotWorking,

		rfidCounter,
		setRfidCounter,
		setRfidStatus,
		rfidStatus,
		setRfidComment,
		rfidComment,
		setRfidTagId,
		rfidTagId,
		getRfidLogs,
		//Dispenser actions
		// setPreset,
		toggleConnectAgain,
		readRFIDStatus,
	};
});

export default useRfidStore;
