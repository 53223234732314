<script setup lang="ts">
import { useOrderStore, useUserStore } from "@/store";


const orderStore = useOrderStore();
const userStore = useUserStore();

const totalAssetsFilled = () =>{
    return (orderStore.getCurrentOrderAssets.filter(asset => asset?.quantity_dispensed !== null)).length 
}
</script>
<template>
    <div class="card shadow-md rounded-lg px-4 py-2 space-y-4 w-full bg-white">
      <div class="flex flex-wrap text-sm items-center space-x-2 justify-between">        
        <div class="mb-2">
          <p class="font-bold text-gray-700">Order Code:</p>
          <p class="text-gray-600">{{ orderStore.currentOrder.customer_order.order_code }}</p>
          
          <p class="font-bold text-gray-700 mt-2">Total Qty Dispensed:</p>
          <p class="text-gray-600">{{ orderStore.getDispensedQty }} L</p>
        </div>
  
        <div class="mb-2">
          <p class="font-bold text-gray-700">Vehicle No.:</p>
          <p class="text-gray-600">{{ userStore.getVehicleDetails().registration_number }}</p>
          
          <p class="font-bold text-gray-700 mt-2">Total Assets Filled:</p>
          <p class="text-gray-600">{{ totalAssetsFilled() }}</p>
        </div>
      </div>
    </div>
  </template>
