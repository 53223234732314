import { defineStore } from "pinia";
import { ref } from "vue";

export const useDispensingStore = defineStore("dispensingStore", () => {
  const sessionId = ref<string | null>(null);

  const generateSessionId = (): string => {
    sessionId.value = new Date().getTime().toString();
    return sessionId.value;
  };

  const clearSessionId = () => {
    sessionId.value = null;
  };

  return {
    sessionId,
    generateSessionId,
    clearSessionId,
  };
});
