
import { useOrderStore } from '@/store';
import { Task_Value } from '@/sdk';
import { addTotalizerBeforeReading } from '@/services/task.service';
import router from '@/router';

export async function handleTotalizerRouting() {
  const orderStore = useOrderStore();  
  const isTotalizerBeforeMoreThanOne = orderStore.getCurrentOrder?.task_values.filter(
    (task: Task_Value) => task.key === "TOTALIZER_BEFORE_READING"
  );

  if (
    isTotalizerBeforeMoreThanOne &&
    isTotalizerBeforeMoreThanOne.length >= 1 &&
    orderStore.getCurrentOrder.category === "DELIVERY"
  ) {
    await addTotalizerBeforeReading();
    router.replace("/totalizer-after-manual");
  } else {
    router.replace("/totalizer-before-manual");
  }
}
