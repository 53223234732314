<script setup lang="ts">
import CustomerOrder from "@/components/dashboard/CustomerOrder.vue";
import {
	fetchIncompleteDriverOrder,
	fetchUpcomingDriverOrder,
} from "@/services/order.service";
import { useOrderStore } from "@/store/orderStore";
import { onMounted, ref } from "vue";
import router from "@/router";
import $toast from "@/utils/toast";
import { TYPE } from "vue-toastification";
import { useUserStore } from "@/store/userStore";
import NoOrder from "@/components/dashboard/NoOrder.vue";
import Refresh from "@/assets/refresh-cw.svg?component";
import { Task, Task_State_Enum } from "@/sdk";
import { updateTaskState } from "@/services/task.service";

const orderStore = useOrderStore();
const userStore = useUserStore();

const showCTAProgress = ref<boolean>(false);
const startOrderDispensingLabel = ref<string>("Select order");
const selectedOrder = ref<any>(null);
const isSpinning = ref<boolean>(false);

const selectAssetBeforeDispensing = async (order: Task) => {
	selectedOrder.value = order;
	startOrderDispensingLabel.value = "Start order";
};

/**
 * @description Redirects to the choose assets page
 *
 * Before redirecting, following checks are performed:
 * 1. If the currentOrder in OrderStore Pinia is not same as the selected order, update currentOrder
 * 2. If the order is assigned, then update the state to IN_TRANSIT
 * 3. If the order is in transit, then update the state to DISPENSING
 *
 * @param {Task} order - The order to be redirected to
 */
const goToChooseAssets = async () => {
	showCTAProgress.value = true;
	if (!selectedOrder.value) {
		$toast("Please select an order", TYPE.INFO);
		return;
	}
	if (
		orderStore.getCurrentOrder?.id !== selectedOrder.value.id &&
		orderStore.getCurrentOrderType === "incomplete"
	) {
		$toast("Please complete the ongoing order first", TYPE.ERROR);
		return;
	}

	orderStore.setCurrentOrder(selectedOrder.value);

	startOrderDispensingLabel.value = "Starting...";

	if (selectedOrder.value?.state === "ASSIGNED") {
		await updateTaskState(
			orderStore.getCurrentOrder?.id,
			Task_State_Enum.InTransit,
		);
	}

	if (orderStore.getHSECompleted) {
		await router.push("/choose-assets/" + selectedOrder.value.id);
	} else {
		await router.push("/healthchecks/" + selectedOrder.value.id);
	}
};

const refetchOrdersManually = async () => {
	try {
		isSpinning.value = true;
		await Promise.all([
			fetchIncompleteDriverOrder(),
			fetchUpcomingDriverOrder(),
		]);
		$toast("Orders refreshed", TYPE.SUCCESS);
	} catch (error) {
		console.error(error);
	} finally {
		isSpinning.value = false;
	}
};

onMounted(async () => {
	try {
		startOrderDispensingLabel.value = "Select order";
		if (!userStore.getShiftSchedule()) {
			return router.replace("/");
		}
		await Promise.all([
			fetchIncompleteDriverOrder(),
			fetchUpcomingDriverOrder(),
		]);
		const timeout = setInterval(async () => {
			await Promise.all([
				fetchIncompleteDriverOrder(),
				fetchUpcomingDriverOrder(),
			]);
		}, 300000);

		return () => clearInterval(timeout);
	} catch (error) {
		console.error(error);
	}
});
</script>

<template>
	<div class="flex flex-col gap-2 w-full h-full p-2">
		<div
			v-if="
				orderStore.getAllOrders.length > 0 ||
				orderStore.getCurrentOrder
			"
			class="relative h-full overflow-y-scroll gap-y-2 flex flex-col">
			<div
				v-if="
					orderStore.currentOrderType === 'incomplete' &&
					orderStore.getCurrentOrder
				">
				<div
					class="card bg-yellow-50 border-yellow-300 rounded-lg"
					:class="
						selectedOrder &&
						selectedOrder.id === orderStore.getCurrentOrder.id
							? 'border-2 shadow-inner'
							: 'border'
					"
					@click="
						selectAssetBeforeDispensing(orderStore.getCurrentOrder)
					">
					<div class="card-body p-2">
						<CustomerOrder :order="orderStore.getCurrentOrder" />
					</div>
				</div>
			</div>
			<div
				v-for="order in orderStore.getAllOrders"
				:key="order.id"
				class="card card-bordered bg-white"
				:class="
					selectedOrder && selectedOrder.id === order.id
						? 'border-2 border-yellow-300 shadow-inner'
						: 'border'
				"
				@click="selectAssetBeforeDispensing(order)">
				<div class="card-body p-2">
					<CustomerOrder :order="order" />
				</div>
			</div>
			<div class="w-full flex justify-around fixed bottom-2">
				<button
					@click="goToChooseAssets"
					type="submit"
					class="shadow-md btn w-40 rounded-md bg-emerald-600 text-white focus:bg-emerald-600 hover:bg-emerald-600">
					<span
						v-if="showCTAProgress"
						class="loading loading-spinner loading-xs"></span>

					{{ startOrderDispensingLabel }}
				</button>
			</div>
		</div>
		<div
			class="flex flex-col items-center justify-center h-full w-full p-2"
			v-else>
			<NoOrder />
			<button
				@click="refetchOrdersManually"
				type="submit"
				class="shadow-md btn w-40 rounded-md bg-orange-600 text-white focus:bg-orange-600 hover:bg-orange-600">
				<span :class="{ spin: isSpinning }">
					<Refresh />
				</span>
			</button>
		</div>
	</div>
</template>

<style scoped>
.spin {
	animation: spin 1s linear infinite;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
</style>
