import { defineStore } from "pinia";
import { ref } from "vue";

export const useCustomerStore = defineStore("customerStore", () => {
  //state
  const user = ref<any>(null);
  const userRoles = ref<any>();
  const userIdToken = ref<any>();
  const loadingUser = ref<boolean>(true);
  const organisationUserId = ref<any>("");
  const phoneNumber = ref<string>("");

  //loading states
  const showOTPSentLoader = ref<boolean>(true);

  //actions
  function setUser(firebaseUser: any) {
    user.value = firebaseUser;
  }
  function setUserRoles(userRole: any) {
    userRoles.value = userRole;
  }
  function setUserIdToken(token: any) {
    userIdToken.value = token;
  }
  function getUserIdToken() {
    return userIdToken.value;
  }
  function setLoadingUser(loadingState: boolean) {
    loadingUser.value = loadingState;
  }
  const setOrganisationUserId = (id: any) => {
    organisationUserId.value = id;
  };
  const setPhoneNumber = (phone: string) => {
    phoneNumber.value = phone;
  };

  //loader state control
  const toggleShowOTPSentLoader = (state: boolean) => {
    showOTPSentLoader.value = state;
  };

  return {
    user,
    userRoles,
    organisationUserId,
    userIdToken,
    loadingUser,
    phoneNumber,
    showOTPSentLoader,
    setUser,
    setUserRoles,
    setUserIdToken,
    getUserIdToken,
    setLoadingUser,
    setOrganisationUserId,
    setPhoneNumber,
    toggleShowOTPSentLoader,
  };
});

export default useCustomerStore;
