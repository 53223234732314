import { GraphQLClient } from "graphql-request";
import { getSdk } from "@/sdk";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "@/firebase";
import { useUserStore } from "@/store/userStore";

const auth = getAuth(app);

export const getToken = async () => {
	return new Promise((resolve) => {
		onAuthStateChanged(auth, async (user) => {
			if (user) {
				const userStore = useUserStore();
				const userToken = await user.getIdToken(true);
				userStore.setAuthToken(userToken);
				resolve(true);
			} else resolve(false);
		});
	});
};

const client = (async () => {
	try {
		await getToken();
		const userStore = useUserStore();
		const graphQLClient = new GraphQLClient(
			import.meta.env.VITE_GRAPHQL_SCHEMA_PATH,
			{
				headers: () => ({
					Authorization: "Bearer " + userStore.getAuthToken(),
				}),
			}
		);
		return getSdk(graphQLClient);
	} catch (error) {
		throw new Error("Error initializing client", { cause: error });
	}
})();

export default client;
