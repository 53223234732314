//dependencies
import { createRouter, createWebHistory } from "vue-router";
import Login from "./pages/Login.vue";
import Dashboard from "./pages/Dashboard.vue";
import HealthChecks from "./pages/HealthChecks.vue";
import NoShift from "./pages/NoShift.vue";
import ChooseAsset from "./pages/ChooseAsset.vue";
import { getCurrentUser } from "./firebase";
import { getDriverShiftSchedule } from "./services/user.service";
import Odometer from "./pages/Odometer.vue";
import TotalizerBeforeManual from "./pages/Totalizer-Before-Manual.vue";
import TotalizerAfterManual from "./pages/Totalizer-After-Manual.vue";
import Challan from "./pages/Challan.vue";

let shiftInterval: NodeJS.Timeout | null = null;

const routes = [
	{
		path: "/",
		component: Login,
		meta: { hideNavbar: true },
	},
	{
		path: "/dashboard",
		name: "Dashboard",
		component: Dashboard,
		meta: { requiresAuth: true, requiresShift: true },
	},
	{
		path: "/healthchecks/:taskID",
		component: HealthChecks,
		meta: { requiresAuth: true, hideNavbar: true },
	},
	{
		path: "/no-shift",
		component: NoShift,
		meta: { requiresAuth: true, hideNavbar: true },
	},
	{
		path: "/choose-assets/:taskID",
		component: ChooseAsset,
		name: "Choose Assets",
		meta: { requiresAuth: true },
	},
	{
		path: "/odometer",
		name: "Odometer Reading",
		component: Odometer,
		meta: { requiresAuth: true },
	},
	{
		path: "/totalizer-before-manual",
		component: TotalizerBeforeManual,
		name: "Totalizer Before",
		meta: { requiresAuth: true },
	},
	{
		path: "/totalizer-after-manual",
		component: TotalizerAfterManual,
		name: "Quantity Dispensed",
		meta: { requiresAuth: true },
	},
	{
		path: "/challan",
		component: Challan,
		name: "Challan",
		meta: { requiresAuth: true },
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach(async (to) => {
	if (to.meta.requiresAuth) {
		const user = await getCurrentUser();
		if (!user) {
			return router.push("/");
		}
	}
	if (to.meta.requiresShift) {
		const driverShift = await getDriverShiftSchedule();
		if (!driverShift) {
			return router.push("/no-shift");
		}
	}
	return true;
});

router.afterEach(async (to) => {
	if (to.meta.requiresShift) {
		shiftInterval = setInterval(async () => {
			const driverShift = await getDriverShiftSchedule();
			if (!driverShift) {
				shiftInterval && clearInterval(shiftInterval);
				return router.push("/no-shift");
			}
		}, 300000);
	} else {
		shiftInterval && clearInterval(shiftInterval);
	}
});

export default router;
